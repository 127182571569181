import React, { PureComponent } from "react";

export const iconList = (
  <symbol id="icon-comcast-logo" viewBox="0 0 99 35">
    <path d="M22.2177 13.1354C18.9913 13.1354 16.3657 10.5097 16.3657 7.28427C16.3657 4.06038 18.9913 1.43528 22.2177 1.43528C25.4441 1.43528 28.0692 4.06038 28.0692 7.28427C28.0692 10.5097 25.4441 13.1354 22.2177 13.1354ZM22.2177 0.00012207C18.2013 0.00012207 14.9341 3.26747 14.9341 7.28427C14.9341 11.3009 18.2013 14.5706 22.2177 14.5706C26.2342 14.5706 29.5012 11.3009 29.5012 7.28427C29.5012 3.26747 26.2342 0.00012207 22.2177 0.00012207Z" />
    <path d="M85.0248 7.37717C84.6329 7.17207 82.2573 5.94734 81.9147 5.77021C80.6679 5.13445 80.2257 4.56995 80.2257 3.6149C80.2257 2.28995 81.3139 1.43361 83.002 1.43361C83.9931 1.43361 84.9517 1.86927 85.5125 2.1854C85.6152 2.2415 85.7353 2.27247 85.861 2.27247C86.2555 2.27247 86.5768 1.95184 86.5768 1.5568C86.5768 1.29178 86.4326 1.06055 86.218 0.936528C85.5104 0.542322 84.3033 0.00012207 83.002 0.00012207C80.5232 0.00012207 78.7914 1.48722 78.7914 3.6149C78.7914 5.52683 80.0476 6.42861 81.2775 7.05654C81.6212 7.23167 84.0256 8.47155 84.3865 8.6615C85.3364 9.15892 85.861 9.90438 85.861 10.7627C85.861 11.9132 84.8869 13.1363 83.0827 13.1363C81.3286 13.1363 79.9658 11.9591 79.5891 11.5976L79.4783 11.4899L78.417 12.4559L78.5422 12.5794C79.0125 13.0474 80.7333 14.5706 83.0827 14.5706C85.7176 14.5706 87.2926 12.6339 87.2926 10.7627C87.2926 9.36534 86.4671 8.13062 85.0248 7.37717Z" />
    <path d="M7.28315 1.43516C8.84716 1.43516 10.3171 2.04311 11.4207 3.14699C11.683 3.40802 12.1756 3.40802 12.434 3.14699C12.5708 3.01281 12.6442 2.83319 12.6442 2.64058C12.6442 2.45047 12.5708 2.26885 12.434 2.135L12.3472 2.04478C10.9823 0.726318 9.18277 0 7.28315 0C3.26718 0 0 3.26735 0 7.28415C0 11.3008 3.26718 14.5705 7.28315 14.5705C9.40318 14.5705 11.3128 13.6604 12.6439 12.2104L11.6304 11.1951C10.5585 12.3859 9.00714 13.1353 7.28315 13.1353C4.05709 13.1353 1.43199 10.5095 1.43199 7.28415C1.43199 4.06042 4.05709 1.43516 7.28315 1.43516Z" />
    <path d="M56.6626 1.43516C58.2254 1.43516 59.6962 2.04311 60.7996 3.14699C61.0618 3.40802 61.5549 3.40802 61.8135 3.14699C61.9499 3.01281 62.0246 2.83319 62.0246 2.64058C62.0246 2.45047 61.9499 2.26885 61.8135 2.135L61.7262 2.04478C60.3606 0.726318 58.5625 0 56.6626 0C52.6463 0 49.3784 3.26735 49.3784 7.28415C49.3784 11.3008 52.6463 14.5705 56.6626 14.5705C58.7814 14.5705 60.6915 13.6604 62.0236 12.2104L61.0092 11.1951C59.9379 12.3859 58.3862 13.1353 56.6626 13.1353C53.436 13.1353 50.8109 10.5095 50.8109 7.28415C50.8109 4.06042 53.436 1.43516 56.6626 1.43516Z" />
    <path d="M44.3237 0C44.0546 0 43.8431 0.173297 43.7264 0.398035C43.6089 0.62144 39.4405 10.9329 39.4403 10.9329C39.4403 10.9329 35.2715 0.62144 35.1538 0.398035C35.0371 0.173297 34.826 0 34.5567 0C34.2427 0 34.0058 0.230231 33.9227 0.522389C33.8398 0.815047 31.4879 13.7937 31.4879 13.7937C31.4798 13.8357 31.4751 13.8791 31.4751 13.9239C31.4751 14.2811 31.7658 14.5705 32.1217 14.5705C32.4361 14.5705 32.6993 14.3452 32.7563 14.0474L34.7929 2.94472C34.7929 2.94472 38.7228 12.6539 38.8383 12.8849C38.9537 13.1158 39.1669 13.2943 39.4405 13.2943C39.7135 13.2943 39.9269 13.1158 40.0419 12.8849C40.1574 12.6539 44.0874 2.94472 44.0874 2.94472L46.2047 14.4896H47.5183C47.5183 14.4896 45.0407 0.815047 44.9578 0.522389C44.8744 0.230231 44.6379 0 44.3237 0Z" />
    <path d="M68.0564 7.68685L70.2219 2.36024L72.3898 7.68685H68.0564ZM70.2219 4.68158e-07C69.9492 -0.000332476 69.7389 0.17696 69.6204 0.408523C69.5027 0.63942 64.2256 13.6722 64.2256 13.6722C64.1934 13.7496 64.1758 13.8348 64.1758 13.9239C64.1758 14.2811 64.4653 14.5706 64.8222 14.5706C65.0944 14.5706 65.3281 14.4005 65.4228 14.1604L67.4721 9.12117H72.9735L75.1584 14.4896H76.554C76.554 14.4896 70.9384 0.63942 70.823 0.408523C70.7073 0.17696 70.4949 -0.000332476 70.2219 4.68158e-07Z" />
    <mask
      height="15"
      id="mask0"
      maskUnits="userSpaceOnUse"
      width="10"
      x="89"
      y="0"
    >
      <path
        d="M98.5785 0.0776367V14.4896H89.0347V0.0776367H98.5785V0.0776367Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path d="M98.5785 0.794299C98.5785 0.397929 98.2556 0.0776367 97.8607 0.0776367H89.75C89.355 0.0776367 89.0347 0.397929 89.0347 0.794299C89.0347 1.18934 89.355 1.50996 89.75 1.50996H93.1504V14.4896H94.4628V1.50996H97.8607C98.2556 1.50996 98.5785 1.18934 98.5785 0.794299Z" />
    </g>
    <path d="M43.0988 19.5951C42.3812 19.5951 41.937 20.1007 41.937 20.7208V34.0828H44.2595V20.7208C44.2595 20.1007 43.8171 19.5951 43.0988 19.5951Z" />
    <path d="M16.7523 28.358L16.7535 20.7244C16.7535 20.1043 16.2975 19.5992 15.5925 19.5992C14.8775 19.5992 14.4292 20.1043 14.4292 20.7244V28.2891C14.4292 31.5276 16.7114 34.1602 20.344 34.1602C23.9699 34.1602 26.2574 31.5276 26.2574 28.2891L26.2595 19.673H23.9323V28.358C23.9323 30.2691 22.6612 31.9784 20.344 31.9784C18.0242 31.9784 16.7523 30.2691 16.7523 28.358Z" />
    <path d="M73.0058 21.9446C73.7665 21.9446 74.2342 21.4556 74.2342 20.8087C74.2342 20.161 73.7682 19.6719 73.0058 19.6719H65.8946C65.233 19.6719 64.7681 20.1407 64.7681 20.7961V32.9642C64.7681 33.5994 65.242 34.0837 65.8946 34.0837H74.1578V31.8182H67.0872V27.6351H72.4193C73.2278 27.6351 73.6747 27.1545 73.6747 26.5022C73.6747 25.8505 73.232 25.3695 72.4193 25.3695H67.0872V21.9446H73.0058Z" />
    <path d="M6.66486 31.8601H3.03144V27.5508L6.66486 27.5517C8.33474 27.5517 9.13081 28.5215 9.13081 29.7053C9.13081 30.8901 8.28496 31.8601 6.66486 31.8601ZM3.03144 21.9249H6.5157C7.82517 21.9249 8.46126 22.7062 8.46126 23.6772C8.46126 24.6409 7.76307 25.4306 6.5157 25.4306H3.03144V21.9249ZM9.35854 26.2818C9.74359 26.0363 10.8125 25.1956 10.8125 23.3278C10.8125 21.1183 9.23186 19.6724 6.81002 19.6724H1.83151C1.15946 19.6724 0.708984 20.1313 0.708984 20.7952V34.0829H7.10151C9.69149 34.0829 11.4814 32.1976 11.4814 29.7857C11.4814 27.2164 9.76856 26.4716 9.35854 26.2818Z" />
    <path d="M50.008 19.5951C49.3055 19.5951 48.8516 20.095 48.8516 20.7093V33.0382C48.8516 33.6595 49.3976 34.1572 50.0184 34.1572C50.6255 34.1572 51.171 33.6595 51.171 33.0382V24.1426C51.171 24.1426 56.8224 31.3703 58.3248 33.2798C58.6994 33.7554 58.9641 34.1572 59.6341 34.1572C60.3078 34.1572 60.7598 33.6695 60.7598 33.0374V19.6707H58.4412V29.6472C58.4412 29.6472 52.1394 21.5638 51.2601 20.4443C50.8602 19.9312 50.5423 19.5951 50.008 19.5951Z" />
    <path d="M28.9604 32.0223C29.2559 32.4069 30.614 34.1698 33.6118 34.1698C36.5476 34.1698 38.5497 32.4778 38.5497 30.1204C38.5497 28.5713 37.9386 27.321 35.645 26.2173C35.0314 25.9193 33.3032 25.0909 33.3032 25.0909C32.3828 24.6459 31.9793 24.1174 31.9793 23.4051C31.9793 22.6466 32.6034 21.8327 34.1184 21.8327C34.9811 21.8327 35.6495 22.1101 36.0069 22.2724C36.182 22.3523 36.5972 22.5514 36.9869 22.5514C37.4965 22.5514 38.0498 22.189 38.0498 21.4527C38.0498 20.8362 37.6077 20.5014 37.2225 20.3192C36.6014 20.0258 35.6343 19.6027 34.011 19.6027C31.4452 19.6027 29.5423 21.2293 29.5423 23.4755C29.5423 25.5424 30.9262 26.5667 32.6072 27.3726C32.9256 27.5281 34.1634 28.1264 34.5033 28.2907C35.5601 28.8024 36.1161 29.2903 36.1161 30.1791C36.1161 31.1435 35.2213 31.9442 33.8599 31.9442C31.9108 31.9442 30.8729 30.6656 30.6823 30.4501L28.9604 32.0223Z" />
    <path d="M76.5249 32.0223C76.8214 32.4069 78.1873 34.1698 81.1845 34.1698C84.116 34.1698 86.1149 32.4778 86.1149 30.1204C86.1149 28.5713 85.5151 27.321 83.2094 26.2173C82.5988 25.9193 80.8713 25.0909 80.8713 25.0909C79.9482 24.6459 79.5449 24.1174 79.5449 23.4051C79.5449 22.6466 80.1678 21.8327 81.691 21.8327C82.5507 21.8327 83.2144 22.1101 83.575 22.2724C83.7515 22.3523 84.1655 22.5514 84.5605 22.5514C85.0696 22.5514 85.6259 22.189 85.6259 21.4527C85.6259 20.8362 85.1841 20.5014 84.7956 20.3192C84.17 20.0258 83.1998 19.6027 81.5848 19.6027C79.0226 19.6027 77.1117 21.2293 77.1117 23.4755C77.1117 25.5424 78.4994 26.5667 80.1723 27.3726C80.4949 27.5281 81.7366 28.1264 82.0799 28.2907C83.1255 28.8024 83.6849 29.2903 83.6849 30.1791C83.6849 31.1435 82.7854 31.9442 81.4325 31.9442C79.4753 31.9442 78.4452 30.6656 78.2559 30.4501L76.5249 32.0223Z" />
    <path d="M88.3174 32.0223C88.6139 32.4069 89.963 34.1698 92.9605 34.1698C95.8932 34.1698 97.9068 32.4778 97.9068 30.1204C97.9068 28.5713 97.2916 27.321 95.0026 26.2173C94.3848 25.9193 92.6487 25.0909 92.6487 25.0909C91.7404 24.6459 91.3367 24.1174 91.3367 23.4051C91.3367 22.6466 91.9603 21.8327 93.4679 21.8327C94.3342 21.8327 95.0071 22.1101 95.3655 22.2724C95.5358 22.3523 95.942 22.5514 96.3363 22.5514C96.8457 22.5514 97.4034 22.189 97.4034 21.4527C97.4034 20.8362 96.9599 20.5014 96.5721 20.3192C95.9465 20.0258 94.9922 19.6027 93.3607 19.6027C90.7995 19.6027 88.9017 21.2293 88.9017 23.4755C88.9017 25.5424 90.2751 26.5667 91.9648 27.3726C92.2806 27.5281 93.5131 28.1264 93.8564 28.2907C94.918 28.8024 95.4712 29.2903 95.4712 30.1791C95.4712 31.1435 94.5786 31.9442 93.2098 31.9442C91.2668 31.9442 90.2215 30.6656 90.0325 30.4501L88.3174 32.0223Z" />
  </symbol>
);

export class RocketfuelIcons extends PureComponent {
  static displayName = "RocketfuelIcons";

  render() {
    return iconList;
  }
}

export default RocketfuelIcons;
