const loggingBaseUrl =
  'https://dev.core-gateway.np.api-business.comcast.com/logging-api';
const authRedirectUrl =
  'https://dev.self-service.np.digital.business.comcast.com/oauth/oauth2';
const rocketfuelBaseUrl = {
  baseUrl: 'http://localhost:8080/api',
  sensitiveFields: null
};

export default {
  accountAppRedirectUrl:
    'https://dev.self-service.np.digital.business.comcast.com/account/',

  clientId: 'comcast-business-myaccount-development',
  featureFlags: {},
  fidoConfig: {
    headers: {
      'tracking-id': '',
      'x-api-key': 'tgnvhW9jET8J9pQT3iET36M3DvVYRgCw5EbBwSjo'
    },
    dataApiConfig: {
      // https://static.np.digital.business.comcast.com/rocketfuel-content-develop/dev/
      content: {
        baseUrl: '/api/content/app/voice/rocketfuel',
        sensitiveFields: null
      },
      logging: {
        baseUrl: loggingBaseUrl,
        sensitiveFields: null
      },
      initialState: rocketfuelBaseUrl
    },
    initialStateRetries: 4
  },

  loggerConfig: {
    appSettings: {
      applicationId: 'ss-customer-hub',
      apiKey: 'chbYB2zwnX6bWTrCxdhGv9Uj2p3ayJd881e2dPih',
      loggingEndpoint: {
        url: '/event',
        baseURL: loggingBaseUrl
      }
    },
    environment: 'dev',
    navEnvironment: 'dev'
  },
  hubApiConfig: {
    authRedirectUrl
  }
};
