import React from 'react';
import { ReactNode } from 'prop-types';

import Header from 'containers/Layout/Header/Header';

export default function Layout({ children }) {
  const baseClass = 'rocketfuel-layout';

  return (
    <div className={baseClass}>
      <div className={`${baseClass}-container`}>
        <main className="rocketfuel-main">
          <Header />
          {children}
        </main>
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: ReactNode
};
