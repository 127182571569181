import contentReducer from 'modules/application/content';
import { buildActionNames } from 'modules/utilities';

const MODULE_PREFIX = 'bve/application';

// Action types --------------------------------------
export const types = buildActionNames(MODULE_PREFIX, [
  'APP_INITIALIZE',
  'APP_INITIAL_STATE_SET',
  'ERROR_TRACK',
  'PAGE_TRACK'
]);

// Action creators -----------------------------------
const initializeApp = options => ({
  type: types.APP_INITIALIZE,
  payload: {
    ...options
  }
});

const setAppInitialState = data => ({
  type: types.APP_INITIAL_STATE_SET,
  payload: {
    ...data
  }
});

const trackError = payload => ({
  type: types.ERROR_TRACK,
  payload
});

const trackPage = (pageName, detail, adminTrack = false, adminType) => ({
  type: types.PAGE_TRACK,
  payload: {
    adminTrack,
    adminType,
    detail,
    pageName
  }
});

export const actions = {
  initializeApp,
  setAppInitialState,
  trackError,
  trackPage
};

// Initial state -------------------------------------
export const initialState = {
  content: {},
  common: {},
  errors: {
    initialState: {
      message: '',
      show: false
    }
  },
  hydrated: false,
  initialStateLoaded: false
};

// Reducers ------------------------------------------
const reducer = (state = initialState, action = {}) => {
  if (!state.hydrated) {
    // Merge initial state with preloaded state
    return {
      ...initialState,
      ...state,
      hydrated: true,
      content: contentReducer(state.content, action)
      // common: commonReducer(state.common, action)
    };
  }

  switch (action.type) {
    case types.APP_INITIAL_STATE_SET:
      return {
        ...state,
        ...action.payload
      };

    default:
      return {
        ...state,
        content: contentReducer(state.content, action)
        // common: commonReducer(state.common, action)
      };
  }
};

export default reducer;
