const MODULE_PREFIX = 'bve/content';

// Action types --------------------------------------
const CONTENT_LOAD = `${MODULE_PREFIX}/CONTENT_LOAD`;
const CONTENT_SET = `${MODULE_PREFIX}/CONTENT_SET`;

export const types = {
  CONTENT_LOAD,
  CONTENT_SET
};

// Action creators -----------------------------------
const loadContent = () => ({
  type: CONTENT_LOAD
});

const setContent = payload => ({
  type: CONTENT_SET,
  meta: {
    loaded: {
      content: true
    }
  },
  payload
});

export const actions = {
  loadContent,
  setContent
};

// Initial state -------------------------------------
export const initialState = {
  loaded: {
    content: false
  },
  data: {},
  errors: {
    content: {
      message: '',
      show: false
    }
  }
};

// Reducers ------------------------------------------
const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CONTENT_SET:
      return {
        ...state,
        loaded: {
          ...state.loaded,
          ...action.meta.loaded
        },
        data: {
          ...state.data,
          ...action.payload
        }
      };
    default:
      return state;
  }
};

export default reducer;
