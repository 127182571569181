import { all, put, takeLatest } from 'redux-saga/effects';

import {
  actions as applicationActions,
  types as appTypes
} from 'modules/application';

import { getCookieValue } from './helpers';
import config from 'config';

function* initializeAppWorker() {
  const comAuthCore = localStorage.getItem('cb.comAuthCore');
  const token = localStorage.getItem('cb.accessToken');
  const trackingId = getCookieValue('cb_ucid') || '';
  const cimaToken = `Bearer ${token}`;

  const data = {
    rawResponse: true,
    withCredentials: true,
    headers: {
      Authorization: cimaToken,
      'tracking-id': trackingId,
      'com-auth-core': comAuthCore
    },
    initialStateLoaded:
      window.location.hostname === 'localhost' ? true : Boolean(token),
    dataApiConfig: config.fidoConfig.dataApiConfig
  };

  yield put(applicationActions.setAppInitialState(data));
}

function* initializeAppWatch() {
  yield takeLatest(appTypes.APP_INITIALIZE, initializeAppWorker);
}

export const watchers = {
  initializeAppWatch
};

function* saga() {
  yield all([initializeAppWatch()]);
}

export default saga;
