import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { Spinner, Sprite } from 'bsd-react-ui-kit';
import { ActionIcons } from 'bve-react-ui-kit';

import { actions as appActions } from 'modules/application';

import { actions as contentActions } from 'modules/application/content';
import appSelectors from 'modules/application/selectors';

import routes from 'routes';

import Layout from 'containers/Layout/Layout';
import RocketfuelIcons from 'components/RocketfuelIcon';

export default function AppContainer() {
  const baseClass = 'rocketfuel-app';
  const dispatch = useDispatch();

  const isAppLoaded = useSelector(appSelectors.getInitailStateLoaded);

  useEffect(() => {
    if (!isAppLoaded) {
      dispatch(appActions.initializeApp());
    }
  }, [isAppLoaded]);

  useEffect(() => {
    dispatch(contentActions.loadContent());
  }, []);

  return (
    <div className={baseClass} id="rocketfuel-app">
      <Sprite>
        <ActionIcons />
        <RocketfuelIcons />
      </Sprite>
      {!isAppLoaded ? (
        <Spinner show={!isAppLoaded} />
      ) : (
        <Layout>
          <Switch>
            {routes.map(route => (
              <Route key={route.pathKey} exact {...route} />
            ))}
          </Switch>
        </Layout>
      )}
    </div>
  );
}
