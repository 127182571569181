export const getIframeSrc = ({ baseUrl, clientId, provider }) =>
  `${baseUrl}/${provider}/passive?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(
    window.location.origin
  )}`;

export const getSilentIframe = (src, provider) => {
  const iframe = document.createElement('iframe');

  iframe.id = `${provider}-passive-auth`;
  iframe.style.visibility = 'hidden';
  iframe.style.position = 'fixed';
  iframe.style.left = '-1000px';
  iframe.style.top = '0';
  iframe.width = '0';
  iframe.height = '0';
  iframe.src = src;

  return iframe;
};

export const getParams = params => {
  const searchParams = new URLSearchParams(
    window.location.search?.substring?.(1)
  );

  try {
    if (typeof params === 'string') {
      return searchParams.get(params);
    } else if (Array.isArray(params)) {
      return Object.fromEntries(
        Array.from(searchParams.entries()).filter(([key]) =>
          params.includes(key)
        )
      );
    }

    throw new Error('Params must be an array or an array of strings');
  } catch (error) {
    console.error(error);

    return null;
  }
};

export const getCookieValue = name =>
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
