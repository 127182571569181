import React, { Component } from "react";

import { Link, Text } from "bsd-react-ui-kit";

class PageNotFound extends Component {
  render() {
    const message = "We're sorry, this page does not exist.";

    return (
      <div className="bsd-page bsd-page--not-found">
        <Text element="h1" modifier="bold" type="display">
          Page not found
        </Text>
        <Text element="p">{message}</Text>
        <Text element="p">
          Please visit the{" "}
          <Link external to="/my-account">
            My Account
          </Link>{" "}
          homepage or search{" "}
          <Link external to="http://businesshelp.comcast.com/help-and-support">
            Help &amp; Support
          </Link>{" "}
          to find what you are looking for.
        </Text>
      </div>
    );
  }
}

export default PageNotFound;
