const methods = {
  DELETE: 'DELETE',
  GET: 'GET',
  PATCH: 'PATCH',
  POST: 'POST',
  PUT: 'PUT'
};

export const endpointTypes = {
  CONTENT: 'content'
};

const endpoints = {
  [endpointTypes.CONTENT]: {
    key: endpointTypes.CONTENT,
    endpoints: {
      getS3Content: () => ({
        url: 'content.json',
        method: methods.GET,
        key: endpointTypes.CONTENT
      })
    }
  }
};

export default endpoints;
