import React from 'react';

import { Icon } from 'bsd-react-ui-kit';

export default function Header() {
  const baseClass = 'rocketfuel-header';

  return (
    <>
      <div className={`${baseClass}-main`} />
      <div className={baseClass}>
        <a data-tracking='{"eventAction": "Comcast Business Logo"}' href="">
          <Icon
            className={`${baseClass}-logo`}
            description="Comcast Business"
            modifier="white"
            name="comcast-logo"
            size="xl"
          />
        </a>
      </div>
    </>
  );
}
