import defaultConfig from "./default";
import devConfig from "./dev";
import qaConfig from "./qa";
import pqaConfig from "./pqa";
import intConfig from "./int";
import stgConfig from "./stg";
import prodConfig from "./prod";

const currentEnvironment = () => process.env.CONFIG_ENV;

export const getConfig = () => {
  switch (currentEnvironment()) {
    case "dev":
      return devConfig;
    case "qa":
      return qaConfig;
    case "pqa":
      return pqaConfig;
    case "stg-va":
    case "stg-or":
      return stgConfig;
    case "prod-va":
    case "prod-or":
      return prodConfig;
    case "int":
      return intConfig;
    default:
      return defaultConfig;
  }
};

export default getConfig();
