import { all, fork } from 'redux-saga/effects';

import applicationSaga from 'modules/application/sagas';
import contentSaga from 'modules/application/content/sagas';

const sagas = [applicationSaga, contentSaga];

function* rootSaga() {
  yield all(sagas.map(saga => fork(saga)));
}

export default rootSaga;
