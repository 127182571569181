import React, { Component } from "react";
import PropTypes from "prop-types";

import { ServiceError, Text } from "bsd-react-ui-kit";

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    content: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  get heading() {
    const { content } = this.props;

    if (!content || !content.heading) {
      return null;
    }

    return (
      <Text element="h1" type="display">
        {content.heading}
      </Text>
    );
  }

  componentDidCatch() {
    this.setState(() => ({
      hasError: true
    }));
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div className="bve-page-error">
          {this.heading}
          <ServiceError />
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
