import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import fido from '@bsd/ui-utils/fido';

import { actions, types } from 'modules/application/content';

import endpoints, { endpointTypes } from 'modules/endpoints';

import appSelectors from 'modules/application/selectors';

const currentRoute = endpoints[endpointTypes.CONTENT];

function* loadContentWorker() {
  // Select app config
  const apiConfig = yield select(appSelectors.getFidoConfig);

  // Initialize Fido
  yield call(fido.init, apiConfig);

  const endpoint = currentRoute.endpoints.getS3Content();

  try {
    const response = yield call(fido.fetch, endpoint);

    yield put(actions.setContent(response.body));
  } catch (e) {
    // yield put(actions.showGlobalError());
  }
}
export const workers = {
  loadContentWorker
};

function* loadContentWatch() {
  yield takeLatest(types.CONTENT_LOAD, loadContentWorker);
}
export const watchers = {
  loadContentWatch
};

function* saga() {
  yield all([loadContentWatch()]);
}

export default saga;
