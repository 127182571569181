/* eslint-disable import/no-unresolved */
import { createSelector } from 'reselect';

export const getApplicationState = state => state.application;

const getBaseContent = createSelector(
  getApplicationState,
  application => application.content
);

const getContentLoadedStatus = createSelector(
  getBaseContent,
  content => content.loaded.content
);

export const getDataConfig = createSelector(
  getApplicationState,
  application => application.dataApiConfig
);

const getInitailStateLoaded = createSelector(
  getApplicationState,
  application => application.initialStateLoaded
);

export const getHeaders = createSelector(
  getApplicationState,
  application => application.headers
);

export const getFidoConfig = createSelector(
  [getHeaders, getDataConfig],
  (headers, dataApiConfig) => ({
    headers,
    dataApiConfig,
    throwErrors: true
  })
);

const selectors = {
  getBaseContent,
  getContentLoadedStatus,
  getDataConfig,
  getHeaders,
  getFidoConfig,
  getInitailStateLoaded
};

export default selectors;
