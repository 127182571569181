/**
 * Returns an object containing the actions provided converted into a string
 * representation using the provided module name as a prefix
 *
 * @template {string} A
 *
 * @param {string} module Name of the module, used as a prefix
 * @param {A[]} actionList Array of string action names
 *
 * @returns {{ [K in A]: string }}
 */
export const buildActionNames = (module, actionList) => {
  const collection = actionList.reduce(
    (acc, cur) => Object.assign({}, acc, { [cur]: `${module}/${cur}` }),
    {}
  );

  return collection;
};
