import React, { Component, Suspense } from "react";
import { Spinner } from "bsd-react-ui-kit";
import ErrorBoundary from "components/Error/ErrorBoundary";

const Sites = React.lazy(() =>
  import(/* webpackChunkName: "Sites" */ "containers/Sites/Sites")
);

class SitesView extends Component {
  render() {
    return (
      <ErrorBoundary pageKey="callHistory">
        <Suspense fallback={<Spinner show />}>
          <Sites {...this.props} />
        </Suspense>
      </ErrorBoundary>
    );
  }
}

export default SitesView;
