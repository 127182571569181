import PageNotFound from "pages/404/PageNotFound";
import SitesView from "pages/sites/SitesView";

// const LAYOUT_TYPES = {
//   full: "full",
//   details: "details"
// };

const routes = [
  {
    path: "/",
    pathKey: "sites",
    component: SitesView
  }
];

// PageNotFound should be the last route
routes.push({
  path: "*",
  pathKey: "notFound",
  component: PageNotFound
});

export default routes;
