import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
// import Favicon from "react-favicon";
import { AuthProvider } from '@bsd/ui-auth';

import App from 'components/App';
// import favicon from "assets/favicon.png";
import './main.scss';
import config from './config';
import configureStore from 'modules/store';

export const store = configureStore();

const getIsDev = () => window.location.hostname === 'localhost';

const options = {
  clientId: config.clientId,
  scope:
    'openid%20profile%20email%20address%20offline_access%20phone%20urn%3Abusiness-profileapi%20urn%3Abusiness-notificationapi',
  baseUrl: config.hubApiConfig.authRedirectUrl,
  requireAuthentication: true
};

const renderApp = () => (
  <>
    {/* <Favicon url={favicon} /> */}
    <App />
  </>
);

ReactDOM.render(
  <BrowserRouter basename="/voice/rocketfuel">
    <Provider store={store}>
      {getIsDev() ? (
        renderApp()
      ) : (
        <AuthProvider options={options}>{renderApp()}</AuthProvider>
      )}
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);
