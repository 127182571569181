import { combineReducers } from "redux";

import applicationReducer from "modules/application";
import accountReducer from "modules/accounts";

const rootReducer = combineReducers({
  application: applicationReducer,
  account: accountReducer
});

export default rootReducer;
